import React from 'react';
import Spinner from './Spinner';
import PulsingRecordButton from './PulsingRecordButton';
import Header from './Header';
import AdjustAudio from './AdjustAudio';
import PlayPauseLoadingButton from './PlayPauseLoadingButton';
import { BiArrowBack } from 'react-icons/bi';
import { Link } from 'react-router-dom';

export enum InterviewUIState {
  SPEAKING,
  LISTENING,
  PROCESSING,
  PAUSED,
  ERROR
}

interface InterviewUIProps {
  title: string;
  state: InterviewUIState;
  onSkipSpeaking: () => void;
  onStopRecording: () => void;
  processingText?: string;
  onClose: () => void;
  onBack: () => void;
  isAirPlayAvailable: boolean;
  showAirPlayPicker: () => void;
  togglePauseReading: () => void;
  question?: string;
  actionLink?: string;
  actionText?: string;
}

const InterviewUI: React.FC<InterviewUIProps> = ({ onClose, onBack, title, state, onSkipSpeaking, onStopRecording,
  processingText, isAirPlayAvailable, showAirPlayPicker, togglePauseReading, question, actionLink, actionText }) => {

  const logoClassName = () => {
    switch (state) {
      case InterviewUIState.SPEAKING:
        return 'transform scale-100 opacity-100';
      case InterviewUIState.PAUSED:
        return 'transform scale-100 opacity-100';
      case InterviewUIState.LISTENING:
        return 'transform scale-75 opacity-50';
      case InterviewUIState.PROCESSING:
        return 'transform scale-100 opacity-50';
      case InterviewUIState.ERROR:
        return 'transform scale-100 opacity-50';
    }
  }

  const reloadWithRegenerateParam = () => {
    const url = new URL(window.location.href);
    url.searchParams.set('regenerate', 'true');
    window.location.href = url.toString();
  };

  return (
    <div className="h-screen-dynamic flex flex-col items-center justify-between bg-white">
      <div className="w-full">
        <Header title={title} onClose={onClose} />
        <AdjustAudio isAirPlayAvailable={isAirPlayAvailable} showAirPlayPicker={showAirPlayPicker} />
      </div>
      <div className={`flex items-center justify-center flex-col transition-transform duration-500 ${logoClassName()}`}>
        <img src="img/main-logo.png" alt="Logo" className="w-48 cursor-pointer" />
      </div>
      {question && (
        <p className="text-black text-2xl text-center mt-6 max-w-2xl px-4">
          {question}
        </p>
      )}
      <div className="w-full bg-skin text-center rounded-t-3xl p-6 h-64 flex items-center justify-center relative">
        <div className={`transition-opacity duration-500 ${state === InterviewUIState.LISTENING ? 'opacity-100 z-10' : 'opacity-0 z-0'} absolute inset-0 flex flex-col items-center justify-center`}>
          <p className="text-gray-800 text-2xl font-bold mb-6">I am Listening…</p>
          <div className="relative w-full">
            <button
              onClick={onBack}
              className="absolute left-6 top-1/2 transform -translate-y-1/2 flex items-center justify-center w-12 h-12 rounded-full bg-gray-200 hover:bg-gray-300 active:bg-gray-400"
            >
              <BiArrowBack className="text-gray-600 text-2xl" />
            </button>
            <div className="flex justify-center">
              <PulsingRecordButton onStopRecording={onStopRecording} />
            </div>
          </div>
          <p className="text-gray-600 mt-6">Tap to Stop Recording</p>
        </div>
        <div className={`transition-opacity duration-500 ${state === InterviewUIState.SPEAKING || state === InterviewUIState.PAUSED ? 'opacity-100 z-10' : 'opacity-0 z-0'} absolute inset-0 flex flex-col items-center justify-center`}>
          <p className="text-gray-800 text-2xl font-bold mb-6">Bespoke is {state === InterviewUIState.SPEAKING ? 'speaking' : 'paused'}</p>
          <div className="flex items-center space-x-4">
            <PlayPauseLoadingButton
              isLoading={false}
              isDisabled={false}
              isPlaying={state === InterviewUIState.SPEAKING}
              onClick={togglePauseReading}
            />
          </div>
        </div>
        <div className={`transition-opacity duration-500 ${state === InterviewUIState.PROCESSING ? 'opacity-100 z-10' : 'opacity-0 z-0'} absolute inset-0 flex flex-col items-center justify-center`}>
          <p className="text-gray-800 text-2xl font-bold mb-4">{processingText || 'Summarizing'}</p>
          <Spinner />
          {actionLink && (
            <Link to={actionLink} replace className="text-gray-600 mt-4">{actionText}</Link>
          )}
        </div>
        <div className={`transition-opacity duration-500 ${state === InterviewUIState.ERROR ? 'opacity-100 z-10' : 'opacity-0 z-0'} absolute inset-0 flex flex-col items-center justify-center`}>
          <p className="text-gray-800 text-2xl font-bold mb-4">Error Generating Feedback</p>
          <button onClick={reloadWithRegenerateParam} className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors">Retry</button>
        </div>
      </div>
    </div>
  );
};

export default InterviewUI;
