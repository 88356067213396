import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './instrument'
import { ErrorBoundary } from "@sentry/react";
import App from './App';
import './index.css';
import ErrorFallback from './components/ErrorFallback';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <ErrorBoundary fallback={<ErrorFallback />}>
    <BrowserRouter >
      <App />
    </BrowserRouter >
  </ErrorBoundary>
);