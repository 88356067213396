import { toast } from "react-toastify";
import * as Sentry from '@sentry/react';

export interface LogExceptionOptions {
  noToast?: boolean;
}

export function logWarning(error: any, message?: string, options?: LogExceptionOptions) {
  if (process.env.REACT_APP_ENVIRONMENT === 'development' && !options?.noToast) {
    toast.warning(message || error.message)
  }
  if (message) {
    console.warn(message)
  }
  console.warn(error)
  Sentry.captureException(error);
}

export function logError(error: any, message?: string, options?: LogExceptionOptions) {
  if (!options?.noToast) {
    toast.error(message || error.message)
  }
  if (message) {
    console.error(message)
  }
  console.error(error)
  Sentry.captureException(error);
}
