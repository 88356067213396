import React from "react";
import * as Sentry from "@sentry/react";
import { hotjar } from 'react-hotjar';

import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

function createUrlRegex(startString?: string) {
  if (!startString)
    return undefined
  const escapedString = startString.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  return [new RegExp(`^${escapedString}\\S+$`)];
}

if (process.env.REACT_APP_ENVIRONMENT !== 'development') {

  // const siteId = 5098002;
  // const hotjarVersion = 6;
  // hotjar.initialize({ id: siteId, sv: hotjarVersion })

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENVIRONMENT,
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        networkCaptureBodies: true,
        networkDetailAllowUrls: createUrlRegex(process.env.REACT_APP_API_URL),
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}
