import React, { useState, useEffect } from 'react';
import Header from './Header';
import { UseCase } from '../models/UseCase';
import InterviewOutline from './InterviewOutline';
import { Tooltip } from 'react-tooltip'; // Make sure to install this package
import api from '../api';
import { useUserContext } from '../hooks/useUserContext';

interface InterviewStartProps {
  onStart: (otherParticipant?: string) => void;
  onClose: () => void;
  title: string;
  recipient: string;
  otherParticipant?: string;
  oneOnOne: boolean;
  useCase: UseCase;
}

const InterviewStart: React.FC<InterviewStartProps> = ({ useCase, onStart, title, otherParticipant, oneOnOne, onClose, recipient }) => {
  const [participantName, setParticipantName] = useState(otherParticipant || '');
  const [nameError, setNameError] = useState(false);
  const { user, setUser } = useUserContext();
  const [useVoice, setUseVoice] = useState(user.useOwnVoice);

  const handleUseVoiceChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const newUseVoice = e.target.checked;
    setUseVoice(newUseVoice);
    const updatedUser = await api.updateUser({ useOwnVoice: newUseVoice });
    setUser(updatedUser);
  };

  const handleStartClick = () => {
    if (oneOnOne && participantName.trim() === '') {
      setNameError(true);
    } else {
      setNameError(false);
      onStart(participantName);
    }
  };

  return (
    <div className="h-screen-dynamic flex flex-col bg-white transition-all duration-500 ease-in-out">
      <Header title="" onClose={onClose} />
      <div className="flex-grow flex items-center justify-center">
        <div className="w-full max-w-md p-6 mx-auto text-center">
          <div className="mb-8">
            <img src={`img/${oneOnOne ? 'two' : 'four'}-people.png`} alt="Icon" className="mx-auto w-24" />
          </div>
          <h1 className="text-2xl font-semibold mb-4">{title}</h1>
          {oneOnOne && <p className="text-gray-500 mb-6">Name of the {recipient}</p>}
          {oneOnOne && (
            <input
              type="text"
              value={participantName}
              onChange={(e) => setParticipantName(e.target.value)}
              className={`w-full max-w-xs px-4 py-2 border ${nameError ? 'border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:border-purple-500 transition duration-300 ease-in-out`}
            />)}
          {nameError && (
            <p className="text-red-500 text-xs mt-2">Please enter the name of the {recipient}.</p>
          )}
          <div className="flex items-center justify-center mt-4 mb-4">
            <input
              type="checkbox"
              id="useVoice"
              checked={useVoice}
              onChange={handleUseVoiceChange}
              className="mr-2"
            />
            <label htmlFor="useVoice" className="text-sm text-gray-700">Use your voice</label>
            <span
              className="ml-2 inline-flex items-center justify-center w-5 h-5 bg-gray-300 rounded-full text-white text-xs font-bold cursor-help"
              data-tooltip-id="voice-tooltip"
              data-tooltip-content="When checked, bespoke will generate audio for the final script in your voice."
            >
              ?
            </span>
            <Tooltip
              id="voice-tooltip"
              place="top"
              style={{ maxWidth: '250px', textAlign: 'center' }}
            />
          </div>
          <button
            className="
              w-full
              max-w-xs
              px-8 py-3 
              mt-6
              text-white 
              bg-gradient-to-r from-purple-500 to-orange-500 
              rounded-md 
              transition 
              duration-300 
              ease-in-out 
              transform 
              hover:scale-105 
              hover:bg-gradient-to-r hover:from-purple-600 hover:to-orange-600 
              active:scale-95 
              focus:outline-none 
              focus:ring-2 
              focus:ring-purple-500 
              focus:ring-opacity-50
            "
            onClick={handleStartClick}
          >
            Start
          </button>
          <p className="text-xs text-gray-400 mt-4">
            Note: We will record your audio to generate the summaries and script for communication.
          </p>
          <InterviewOutline useCase={useCase} />
        </div>
      </div>
    </div>
  );
};

export default InterviewStart;