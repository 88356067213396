import React, { useState, useEffect, useRef } from 'react';
import {
  MdMic,
  MdStop,
  MdPlayArrow,
  MdVolumeUp,
  MdFileDownload
} from 'react-icons/md';

interface AudioDevices {
  inputs: MediaDeviceInfo[];
  outputs: MediaDeviceInfo[];
}

interface Recording {
  url: string;
  timestamp: string;
}

const AudioDeviceDemo: React.FC = () => {
  const [devices, setDevices] = useState<AudioDevices>({ inputs: [], outputs: [] });
  const [selectedInput, setSelectedInput] = useState<string>('');
  const [selectedOutput, setSelectedOutput] = useState<string>('');
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [recordings, setRecordings] = useState<Recording[]>([]);
  const [currentlyPlaying, setCurrentlyPlaying] = useState<number | null>(null);

  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);
  const audioElementRef = useRef<HTMLAudioElement>(new Audio());

  useEffect(() => {
    initializeDevices();
    navigator.mediaDevices.addEventListener('devicechange', initializeDevices);
    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', initializeDevices);
    };
  }, []);

  const initializeDevices = async (): Promise<void> => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      const deviceList = await navigator.mediaDevices.enumerateDevices();

      setDevices({
        inputs: deviceList.filter(device => device.kind === 'audioinput'),
        outputs: deviceList.filter(device => device.kind === 'audiooutput')
      });
    } catch (error) {
      console.error('Error initializing devices:', error);
    }
  };

  const startRecording = async (): Promise<void> => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          deviceId: selectedInput ? { exact: selectedInput } : undefined
        }
      });

      mediaRecorderRef.current = new MediaRecorder(stream);
      audioChunksRef.current = [];

      mediaRecorderRef.current.ondataavailable = (event: BlobEvent) => {
        audioChunksRef.current.push(event.data);
      };

      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
        const audioUrl = URL.createObjectURL(audioBlob);
        setRecordings(prev => [...prev, {
          url: audioUrl,
          timestamp: new Date().toLocaleTimeString()
        }]);
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
    } catch (error) {
      console.error('Error starting recording:', error);
    }
  };

  const stopRecording = (): void => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
      setIsRecording(false);
    }
  };

  const playRecording = async (url: string, index: number): Promise<void> => {
    try {
      if (currentlyPlaying === index) {
        audioElementRef.current.pause();
        audioElementRef.current.currentTime = 0;
        setCurrentlyPlaying(null);
        return;
      }

      // Type assertion for setSinkId as it might not exist in all browsers
      const audio = audioElementRef.current as HTMLAudioElement & { setSinkId?: (id: string) => Promise<void> };

      if (selectedOutput && audio.setSinkId) {
        await audio.setSinkId(selectedOutput);
      }

      audio.src = url;
      audio.onended = () => setCurrentlyPlaying(null);
      await audio.play();
      setCurrentlyPlaying(index);
    } catch (error) {
      console.error('Error playing recording:', error);
    }
  };

  const downloadRecording = (url: string, index: number): void => {
    const a = document.createElement('a');
    a.href = url;
    a.download = `recording-${index + 1}.wav`;
    a.click();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    setSelectedInput(event.target.value);
  };

  const handleOutputChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    setSelectedOutput(event.target.value);
  };

  return (
    <div className="max-w-2xl mx-auto bg-white rounded-lg shadow-lg p-6">
      <div className="border-b pb-4 mb-6">
        <h2 className="text-2xl font-bold text-gray-800">Audio Device Testing</h2>
      </div>

      <div className="space-y-6">
        {/* Device Selection */}
        <div className="space-y-4">
          <div className="flex items-center gap-2">
            <MdMic className="w-5 h-5 text-gray-600" />
            <select
              className="flex-1 px-3 py-2 border rounded-md bg-white focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={selectedInput}
              onChange={handleInputChange}
            >
              <option value="">Select Input Device</option>
              {devices.inputs.map((device) => (
                <option key={device.deviceId} value={device.deviceId}>
                  {device.label || `Microphone ${device.deviceId.slice(0, 5)}...`}
                </option>
              ))}
            </select>
          </div>

          <div className="flex items-center gap-2">
            <MdVolumeUp className="w-5 h-5 text-gray-600" />
            <select
              className="flex-1 px-3 py-2 border rounded-md bg-white focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={selectedOutput}
              onChange={handleOutputChange}
            >
              <option value="">Select Output Device</option>
              {devices.outputs.map((device) => (
                <option key={device.deviceId} value={device.deviceId}>
                  {device.label || `Speaker ${device.deviceId.slice(0, 5)}...`}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Recording Controls */}
        <div className="flex justify-center">
          <button
            onClick={isRecording ? stopRecording : startRecording}
            className={`flex items-center gap-2 px-4 py-2 rounded-md text-white font-medium transition-colors
              ${isRecording
                ? 'bg-red-500 hover:bg-red-600'
                : 'bg-blue-500 hover:bg-blue-600'
              }`}
          >
            {isRecording ? (
              <>
                <MdStop className="w-5 h-5" /> Stop Recording
              </>
            ) : (
              <>
                <MdMic className="w-5 h-5" /> Start Recording
              </>
            )}
          </button>
        </div>

        {/* Recordings List */}
        <div className="space-y-2">
          <h3 className="font-semibold text-gray-800">Recordings</h3>
          {recordings.length === 0 ? (
            <p className="text-sm text-gray-500">No recordings yet</p>
          ) : (
            <div className="space-y-2">
              {recordings.map((recording, index) => (
                <div key={index}
                  className="flex items-center gap-2 p-3 border rounded-md hover:bg-gray-50"
                >
                  <button
                    onClick={() => playRecording(recording.url, index)}
                    className="p-1 rounded-full hover:bg-gray-200"
                    aria-label={currentlyPlaying === index ? "Stop playing" : "Start playing"}
                  >
                    {currentlyPlaying === index ? (
                      <MdStop className="w-5 h-5 text-gray-600" />
                    ) : (
                      <MdPlayArrow className="w-5 h-5 text-gray-600" />
                    )}
                  </button>
                  <span className="flex-1 text-sm text-gray-600">
                    Recording {index + 1} - {recording.timestamp}
                  </span>
                  <button
                    onClick={() => downloadRecording(recording.url, index)}
                    className="p-1 rounded-full hover:bg-gray-200"
                    aria-label="Download recording"
                  >
                    <MdFileDownload className="w-5 h-5 text-gray-600" />
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AudioDeviceDemo;