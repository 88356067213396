import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  FaUserFriends,
  FaUserTie,
  FaUsers,
  FaUserCircle
} from 'react-icons/fa';
import NavigationMenu from './NavigationMenu';
import GradientBespoke from './GradientBespoke';
import { FEEDBACK_TYPES } from '../constants/feedbackTypes';

const MainFeedback: React.FC = () => {
  const navigate = useNavigate();

  const buttonClassNames = `
    bg-white text-black text-xl font-bold py-4 px-4 rounded-lg w-full 
    hover:bg-gray-100 hover:shadow-md 
    active:bg-gray-200 active:shadow-inner 
    transition duration-150 ease-in-out
    flex flex-col items-center
  `;

  const handleSelectFeedbackType = (useCaseId: string) => {
    navigate(`/interview?useCaseId=${useCaseId}&oneOnOne=true`);
  };

  return (
    <div className="h-screen-dynamic flex flex-col items-center justify-center bg-white relative">
      <NavigationMenu />

      <div className="flex-grow flex flex-col items-center justify-center w-full max-w-3xl relative pt-12">
        <img
          src="img/main-logo.png"
          alt="Logo"
          className="w-32 sm:w-48 cursor-pointer"
        />
        <p className="text-center text-sm text-bespokePink mt-2 sm:mt-4">Get started with</p>
        <div className="text-center mt-2 sm:mt-4">
          <div className="text-center mb-4">
            <GradientBespoke />
          </div>
        </div>
        <p className="hidden sm:block text-center text-gray-600 max-w-xl mx-auto mb-4">
          Bespoke feedback conducts personalized AI interviews to help you prepare thoughtful feedback for any professional scenario.
        </p>
      </div>

      <div className="w-full bg-skin text-center rounded-t-3xl relative overflow-hidden">
        <div className="max-w-4xl mx-auto px-4 py-4">
          <p className="text-sm mb-4 sm:mb-6">What type of feedback would you like to prepare?</p>

          <div className="grid grid-cols-2 gap-2 sm:gap-4 max-w-2xl mx-auto">
            <button
              onClick={() => handleSelectFeedbackType(FEEDBACK_TYPES.DIRECT_REPORT)}
              className={buttonClassNames}
            >
              <FaUserFriends className="w-6 h-6 mb-2" />
              <span className="font-semibold">Direct Report</span>
              <span className="text-sm mt-1 font-normal">Feedback for a team member</span>
            </button>

            <button
              onClick={() => handleSelectFeedbackType(FEEDBACK_TYPES.MANAGER)}
              className={buttonClassNames}
            >
              <FaUserTie className="w-6 h-6 mb-2" />
              <span className="font-semibold">Manager</span>
              <span className="text-sm mt-1 font-normal">Feedback for your manager</span>
            </button>

            <button
              onClick={() => handleSelectFeedbackType(FEEDBACK_TYPES.PEER)}
              className={buttonClassNames}
            >
              <FaUsers className="w-6 h-6 mb-2" />
              <span className="font-semibold">Peer</span>
              <span className="text-sm mt-1 font-normal">Feedback for colleagues</span>
            </button>

            <button
              onClick={() => handleSelectFeedbackType(FEEDBACK_TYPES.SELF_EVALUATION)}
              className={buttonClassNames}
            >
              <FaUserCircle className="w-6 h-6 mb-2" />
              <span className="font-semibold">Self Evaluation</span>
              <span className="text-sm mt-1 font-normal">Prepare self-assessment</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainFeedback; 