import React from 'react';
import {
  FaUserFriends,
  FaUserTie,
  FaUsers,
  FaUserCircle
} from 'react-icons/fa';
import { FEEDBACK_TYPES } from '../constants/feedbackTypes';

interface FeedbackTypePopupProps {
  onClose: () => void;
  onSelectFeedbackType: (useCaseId: string, recipientDescription: string) => void;
  email: string;
}

const FeedbackTypePopup: React.FC<FeedbackTypePopupProps> = ({ onClose, email, onSelectFeedbackType }) => {
  const buttonClassNames = `
    bg-white text-black text-xl font-bold py-4 px-4 rounded-lg w-full 
    hover:bg-gray-100 hover:shadow-md 
    active:bg-gray-200 active:shadow-inner 
    transition duration-150 ease-in-out
    flex flex-col items-center
  `;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-lg mx-4">
        <h2 className="text-2xl font-bold mb-4 text-center">What type of feedback</h2>
        <p className="text-center mb-6">should {email} prepare?</p>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <button
            onClick={() => onSelectFeedbackType(FEEDBACK_TYPES.DIRECT_REPORT, 'Name of their direct report?')}
            className={buttonClassNames}
          >
            <FaUserFriends className="w-6 h-6 mb-2" />
            <span className="font-semibold">Direct Report</span>
            <span className="text-sm mt-1 font-normal">Feedback for their direct report</span>
          </button>

          <button
            onClick={() => onSelectFeedbackType(FEEDBACK_TYPES.MANAGER, 'Name of their manager?')}
            className={buttonClassNames}
          >
            <FaUserTie className="w-6 h-6 mb-2" />
            <span className="font-semibold">Manager</span>
            <span className="text-sm mt-1 font-normal">Feedback for their manager</span>
          </button>

          <button
            onClick={() => onSelectFeedbackType(FEEDBACK_TYPES.PEER, 'Name of their peer?')}
            className={buttonClassNames}
          >
            <FaUsers className="w-6 h-6 mb-2" />
            <span className="font-semibold">Peer</span>
            <span className="text-sm mt-1 font-normal">Feedback for their peer</span>
          </button>

          <button
            onClick={() => onSelectFeedbackType(FEEDBACK_TYPES.SELF_EVALUATION, `${email}'s name?`)}
            className={buttonClassNames}
          >
            <FaUserCircle className="w-6 h-6 mb-2" />
            <span className="font-semibold">Self Evaluation</span>
            <span className="text-sm mt-1 font-normal">Prepare self-assessment</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default FeedbackTypePopup; 