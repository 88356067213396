import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import PlayPauseLoadingButton from './PlayPauseLoadingButton';
import api, { Communication, Outline } from '../api';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { debounce, startCase } from 'lodash';
import Header from './Header';
import EmailPopup from './EmailPopup';
import Toast from './Toast';
import { useUserContext } from '../hooks/useUserContext';
import CommunicationOutline from './CommunicationOutline';
import { toast } from 'react-toastify';
import { logError } from '../utils/log-exception';

const saveCommunication = debounce(async (commId: string | undefined, commText: string) => {
  if (!commId) {
    return;
  }
  await api.updateCommunication(commId, commText);
}, 500)


interface EditCommunicationUIProps {
  audioRef: React.RefObject<HTMLAudioElement>
  communication: Communication
  startsPlaying: boolean
}


const EditCommunicationPassAudioRef: React.FC<EditCommunicationUIProps> = ({ audioRef, communication, startsPlaying }) => {
  const { user } = useUserContext();
  const navigate = useNavigate();
  const location = useLocation();

  const [isPlaying, setIsPlaying] = useState(startsPlaying);
  const [isPaused, setIsPaused] = useState(false);
  const [isGeneratingAudio, setIsGeneratingAudio] = useState(false);
  const [text, setText] = useState<string>(communication.text);
  const [audioUrl, setAudioUrl] = useState<string>(communication.audioUrl);
  const [showSharePopup, setShowSharePopup] = useState(false);
  const [pollForAudioUrl, setPollForAudioUrl] = useState(false);
  const shared = communication.userId !== user.id

  const [activeTab, setActiveTab] = useState<string>('script');

  useEffect(() => {
    if (shared) {
      api.markCommunicationShareAsViewed(communication.id);
    }
  }, [shared, communication.id]);

  useEffect(() => {
    if (isPlaying && audioRef.current) {
      audioRef.current.onended = () => setIsPlaying(false);
    }
  }, [])

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    let elapsedTime = 0; // Track elapsed time in seconds

    if (pollForAudioUrl) {
      interval = setInterval(async () => {
        try {
          elapsedTime += 1;
          // Stop polling after 2 minutes (120 seconds)
          if (elapsedTime >= 120) {
            clearInterval(interval);
            interval = undefined;
            setPollForAudioUrl(false);
            toast.error('Audio generation timed out. Please try again.');
            setIsGeneratingAudio(false);
            return;
          }

          const comm = await api.getCommunication(communication.id, false);
          const newAudioUrl = comm.audioUrl;
          if (newAudioUrl !== audioUrl) {
            communication.audioText = text
            setAudioUrl(newAudioUrl);
            setIsGeneratingAudio(false);
            playAudio(newAudioUrl)();
            setPollForAudioUrl(false);
          }
        } catch (e) {
          logError(e, 'Error polling for audio url', { noToast: true });
        }
      }, 1000)
    }

    return () => {
      if (interval) {
        clearInterval(interval);
        interval = undefined;
      }
    }
  }, [pollForAudioUrl])

  const openSharePopup = () => {
    setShowSharePopup(true);
  };

  const handleShareCommunication = async (email: string) => {
    try {
      await api.shareCommunication(communication.id, email);
      toast.success('Feedback shared successfully');
    } catch (e) {
      logError(e, 'Error sharing feedback');
    }
    handleCloseSharePopup()
  }

  const handleCloseSharePopup = () => {
    setShowSharePopup(false);
  };

  const playAudio = (newAudioUrl?: string) => async () => {
    const audioUrlToPlay = newAudioUrl || audioUrl;
    console.log('playAudio ', audioRef.current, audioUrlToPlay);
    if (audioRef.current && audioUrlToPlay) {
      setIsPlaying(true);
      const audio = audioRef.current
      if (!newAudioUrl && isPaused) {
        audio.play();
        setIsPaused(false);
        return;
      } else {
        audio.src = audioUrlToPlay;
        audio.onended = () => setIsPlaying(false);
        audio.play();
      }
    }
  }

  const pauseAudio = () => {
    if (audioRef.current) {
      setIsPlaying(false);
      setIsPaused(true);
      audioRef.current.pause()
    }
  }

  const stopAndResetAudio = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        setIsPlaying(false);
      } else if (isPaused) {
        audioRef.current.currentTime = 0;
        setIsPaused(false);
      }
    }
  }

  const handleTextChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value);
    saveCommunication(communication?.id, event.target.value);
  };

  const regenerateAudio = async () => {
    console.log('regenerateAudio', communication, text);
    if (communication && text) {
      stopAndResetAudio();
      saveCommunication.cancel();
      setIsGeneratingAudio(true);
      try {
        const newAudioUrl = await api.regenerateAudio(communication.id, text);
        communication.audioText = text
        setAudioUrl(newAudioUrl);
        setIsGeneratingAudio(false);
        playAudio(newAudioUrl)();
      } catch (e) {
        // this sometimes times out so we just ignore the error and start polling
        console.log('Error regenerating audio, polling for audio url');
        setPollForAudioUrl(true);
      }
    }
  };

  const handleBack = useCallback(() => {
    if (location.key === 'default') {
      navigate('/');
    } else {
      navigate(-1);
    }
  }, [navigate, location]);

  const textChanged = text !== communication?.audioText;
  const title = shared ? 'Play Feedback' : 'Edit your Feedback';
  const tabs = ['script']
  if (communication.outline) {
    tabs.push('outline')
  }
  if (communication.outputs?.email) {
    tabs.push('email')
  }
  if (communication.outputs?.memoToFile) {
    tabs.push('memoToFile')
  }

  return (
    <div className="h-screen-dynamic flex flex-col bg-white relative">
      <Header
        title={title}
        onBack={handleBack}
        headerButtonText="Share with coworker"
        onHeaderButton={!shared ? openSharePopup : undefined}
        adminLinkPath={`/admin/communication/${communication.id}`}
      />
      {tabs.length > 1 && (
        <div className="w-full max-w-3xl mx-auto px-4 mt-4">
          <div className="flex border-b">
            {tabs.map(tab => (
              <button
                className={`py-2 px-4 ${activeTab === tab ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
                onClick={() => setActiveTab(tab)}
                key={tab}
              >
                {startCase(tab)}
              </button>
            ))}
          </div>
        </div>
      )}
      <div className="flex-grow flex flex-col w-full max-w-3xl mx-auto relative px-4 overflow-y-auto">
        {activeTab === 'script' ? (
          <textarea
            disabled={shared}
            value={text}
            onChange={handleTextChange}
            className="flex-grow w-full px-4 py-2 text-base font-sans border border-gray-300 rounded-t-lg resize-none focus:outline-none focus:ring-2 focus:ring-gray-300"
          />
        ) : activeTab === 'outline' ? (
          <CommunicationOutline communicationId={communication.id} outline={communication.outline} />
        ) : activeTab === 'email' ? (
          <div className="flex-grow w-full px-4 py-2 text-base font-sans overflow-y-auto whitespace-pre-wrap">
            {communication.outputs?.email}
          </div>
        ) : activeTab === 'memoToFile' ? (
          <div className="flex-grow w-full px-4 py-2 text-base font-sans overflow-y-auto whitespace-pre-wrap">
            {communication.outputs?.memoToFile}
          </div>
        ) : null}
      </div>

      <div className="w-full h-40 bg-skin text-center rounded-t-3xl relative overflow-hidden flex flex-col items-center justify-center">
        <div className="flex items-center justify-center space-x-8 w-64">
          <PlayPauseLoadingButton isLoading={isGeneratingAudio} isDisabled={false} isPlaying={isPlaying} onClick={isPlaying ? pauseAudio : textChanged ? regenerateAudio : playAudio()} />
        </div>
        {isGeneratingAudio && (
          <p className="mt-2 text-sm text-gray-600">Regenerating audio...</p>
        )}
      </div>
      {showSharePopup && (
        <EmailPopup buttonText="Next" title="Share Feedback" onClose={handleCloseSharePopup} onEnterEmail={handleShareCommunication} />
      )}
    </div>
  );
};

export default EditCommunicationPassAudioRef;