import React from 'react';

const GradientBespoke: React.FC = () => {
  return (
    <>
      <h1 className="text-3xl sm:text-3xl font-bold">
        <span className="text-transparent bg-clip-text bg-gradient-to-r from-bespokePurple via-bespokePink to-bespokeOrange">
          Bespoke
        </span>
      </h1>
      <h1 className="text-3xl sm:text-3xl font-bold">
        <span className="text-transparent bg-clip-text bg-gradient-to-r from-bespokePurple via-bespokePink to-bespokeOrange">
          Feedback
        </span>
      </h1>
    </>);
};

export default GradientBespoke;