import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import api from "../api";
import FullPageSpinner from "./FullPageSpinner";
import InterviewManager from "./InterviewManager";
import cloneDeep from 'lodash/cloneDeep';
import { UseCase } from "../models/UseCase";
import { Interview } from "../models/Interview";
import { logError } from "../utils/log-exception";


const InterviewLoader: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [interviewData, setInterviewData] = useState<Interview | null>(null);
  const [useCase, setUseCase] = useState<UseCase | undefined>();
  const oneOnOne = !((searchParams.get('oneOnOne') || '').toLowerCase() === 'false');
  const useCaseId = searchParams.get('useCaseId');
  const interviewId = searchParams.get('interviewId');
  const otherParticipant = searchParams.get('recipientName');
  const communicationRequestId = searchParams.get('communicationRequestId') || undefined;


  const fetchData = async () => {
    if (useCaseId) {
      try {
        const useCase = await api.getUseCase(useCaseId);
        setUseCase(useCase)
        const newInterviewData: any = cloneDeep(useCase.script)
        newInterviewData.oneOnOne = useCase.oneOnOne
        newInterviewData.category = useCase.category
        newInterviewData.firstPerson = useCase.firstPerson
        setInterviewData({ ...newInterviewData, otherParticipant });
        console.log({ ...newInterviewData, otherParticipant })
      } catch (error) {
        logError(error, "Failed to load use case");
        onClose();
      } finally {
        setLoading(false);
      }
    }
    if (interviewId) {
      try {
        const interviewData = await api.getInterview(interviewId);
        setInterviewData(interviewData);
        console.log('loaded interview', interviewData)
      } catch (error) {
        logError(error, "Failed to load interview");
        onClose();
      } finally {
        setLoading(false);
      }
    }

  }

  useEffect(() => {
    fetchData();
  }, [])

  const onClose = () => {
    navigate('/');
  }

  if (loading || !interviewData) {
    return <FullPageSpinner />
  }

  return (
    <InterviewManager useCase={useCase} oneOnOne={oneOnOne} interviewData={interviewData} onClose={onClose} communicationRequestId={communicationRequestId} />
  );
}

export default InterviewLoader;