import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../hooks/useUserContext';
import Badge from './Badge';
import api from '../api';

const NavigationMenu: React.FC = () => {
  const { user } = useUserContext();
  const [isOpen, setIsOpen] = useState(false);
  const [isAdminOpen, setIsAdminOpen] = useState(false);
  const [totalShareCount, setTotalShareCount] = useState(0);
  const [totalRequestCount, setTotalRequestCount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBadgeCount = async () => {
      const [data1, data2] = await Promise.all([api.getCommunicationRequestCounts(), api.getCommunicationShareCounts()]);
      setTotalRequestCount(data1.completedUnviewCount + data1.pendingCount);
      setTotalShareCount(data2.shareCount);
    };
    fetchBadgeCount();
  }, []);

  const menuItemClassNames = `
    block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100
  `;

  const toggleMenu = () => setIsOpen(!isOpen);
  const toggleAdminMenu = () => setIsAdminOpen(!isAdminOpen);

  const handleMenuItemClick = (path: string) => {
    setIsOpen(false);
    setIsAdminOpen(false);
    navigate(path);
  };

  return (
    <>
      {/* Desktop Menu */}
      <div className="hidden md:flex justify-between items-center w-full px-8 py-4 bg-white shadow-md">
        {/* Logo - now clickable */}
        <div
          className="flex items-center cursor-pointer"
          onClick={() => handleMenuItemClick('/')}
        >
          <img src="/img/main-logo.png" alt="Bespoke Logo" className="w-10 h-10 mr-2" />
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-bespokePurple via-bespokePink to-bespokeOrange font-bold text-xl">
            Bespoke
          </span>
        </div>

        {/* Navigation Links */}
        <div className="flex items-center gap-6">
          <button
            className="text-gray-700 hover:text-gray-900 text-sm"
            onClick={() => handleMenuItemClick('/communication')}
          >
            Feedback
            {totalShareCount > 0 && <Badge count={totalShareCount} />}
          </button>
          <button
            className="text-gray-700 hover:text-gray-900 text-sm"
            onClick={() => handleMenuItemClick('/request')}
          >
            Requests
            {totalRequestCount > 0 && <Badge count={totalRequestCount} />}
          </button>
          {user.isSuperuser && (
            <div className="relative">
              <button className="text-gray-700 hover:text-gray-900 text-sm" onClick={toggleAdminMenu}>
                Admin
              </button>
              {isAdminOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg">
                  <button className={menuItemClassNames} onClick={() => handleMenuItemClick('/admin/use-case')}>Use Cases</button>
                  <button className={menuItemClassNames} onClick={() => handleMenuItemClick('/admin/user')}>Active Users</button>
                  <button className={menuItemClassNames} onClick={() => handleMenuItemClick('/admin/communication')}>Recent Feedback</button>
                </div>
              )}
            </div>
          )}
          <button
            className="text-gray-700 hover:text-gray-900 text-sm"
            onClick={() => handleMenuItemClick('/logout')}
          >
            Logout
          </button>
        </div>
      </div>

      {/* Mobile Menu Button - hide on desktop */}
      <div className="md:hidden absolute top-0 left-4 mt-4 z-10">
        <button
          className="text-black p-2"
          onClick={toggleMenu}
          aria-label="Toggle menu"
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
          </svg>
          {totalShareCount + totalRequestCount > 0 && (
            <span className="absolute top-1 right-1 bg-red-500 text-white text-xs rounded-full h-3 w-3 flex items-center justify-center text-[10px] font-bold">
              {totalShareCount + totalRequestCount}
            </span>
          )}
        </button>
      </div>

      {/* Overlay */}
      <div
        className={`fixed inset-0 bg-black transition-opacity duration-300 ease-in-out ${isOpen ? 'opacity-50 z-20' : 'opacity-0 -z-10'
          }`}
        onClick={toggleMenu}
      />

      {/* Sliding menu */}
      <div
        className={`fixed top-0 left-0 w-64 h-full bg-white shadow-lg transform transition-transform duration-300 ease-in-out z-30 ${isOpen ? 'translate-x-0' : '-translate-x-full'
          }`}
        onClick={(e) => e.stopPropagation()}
      >
        {/* Top section */}
        <div className="p-4 pb-2">
          <div className="flex items-center mb-2">
            <img src="/img/main-logo.png" alt="Bespoke Logo" className="w-10 h-10 mr-2" />
            <div className="w-px h-8 bg-gray-300 mx-2"></div>
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-bespokePurple via-bespokePink to-bespokeOrange font-bold text-xl">
              Bespoke
            </span>
          </div>
          <div className="w-full h-px bg-gray-300"></div>
        </div>

        {/* Menu items */}
        <div className="pt-0 px-4 pb-4">
          <button className={menuItemClassNames} onClick={() => handleMenuItemClick('/')}>Home</button>
          <button className={menuItemClassNames} onClick={() => handleMenuItemClick('/communication')}>Feedback<Badge count={totalShareCount} /></button>
          <button className={menuItemClassNames} onClick={() => handleMenuItemClick('/request')}>Requests<Badge count={totalRequestCount} /></button>
          <button className={menuItemClassNames} onClick={() => handleMenuItemClick('/logout')}>Logout</button>
        </div>

        {/* Admin items */}
        {user.isSuperuser &&
          <>
            <div className="pt-0 px-4">
              <div className="w-full h-px bg-gray-300"></div>
            </div>

            <div className="pt-2 px-4">
              <div>Admin</div>
              <button className={menuItemClassNames} onClick={() => handleMenuItemClick('/admin/use-case')}>Use Cases</button>
              <button className={menuItemClassNames} onClick={() => handleMenuItemClick('/admin/user')}>Active Users</button>
              <button className={menuItemClassNames} onClick={() => handleMenuItemClick('/admin/communication')}>Recent Feedback</button>
            </div>
          </>
        }

        {/* Terms and Privacy links */}
        <div className="absolute bottom-0 left-0 w-full p-2 text-center text-xs">
          <a href="/privacy.html" className="text-gray-500 hover:text-gray-700">Privacy</a>
          <span className="mx-1 text-gray-500">|</span>
          <a href="/terms.html" className="text-gray-500 hover:text-gray-700">Terms</a>
        </div>
      </div>
    </>
  );
};

export default NavigationMenu;