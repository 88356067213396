import React from 'react';
import GradientBespoke from './GradientBespoke';
import NavigationMenu from './NavigationMenu';

const ChooseUseCase: React.FC<{ onSelectOneToOne: (oneToOne: boolean) => void }> = ({ onSelectOneToOne }) => {


  const buttonClassNames = `
    bg-white text-black text-xl font-bold py-2 px-4 rounded-lg w-full 
    hover:bg-gray-100 hover:shadow-md 
    active:bg-gray-200 active:shadow-inner 
    transition duration-150 ease-in-out
  `;

  return (
    <div className="h-screen-dynamic flex flex-col items-center justify-center bg-white relative">
      <NavigationMenu />

      <div className="flex-grow flex flex-col items-center justify-center w-full max-w-3xl relative pt-12">
        <img
          src="img/main-logo.png"
          alt="Logo"
          className="w-48 cursor-pointer"
        />
        <p className="text-center text-sm text-bespokePink mt-4">Get started with</p>
        <div className="text-center mt-4"><div className="text-center mb-8"><GradientBespoke /></div></div>
      </div>

      <div className="w-full h-64 bg-skin text-center rounded-t-3xl relative overflow-hidden flex items-center justify-center">
        <div className="flex flex-col space-y-4 w-64">
          <p className="text-sm">What type of communication are you preparing for?</p>
          <button className={buttonClassNames} onClick={() => { onSelectOneToOne(true) }}>With an Individual</button>
          <button className={buttonClassNames} onClick={() => { onSelectOneToOne(false) }}>With a Group</button>
        </div>
      </div>

    </div>
  );
};

export default ChooseUseCase;