import React, { useEffect, useState } from 'react';
import { FaChevronDown, FaChevronRight, FaClock } from 'react-icons/fa';
import api from '../api';
import FullPageSpinner from './FullPageSpinner';
import { UseCase } from '../models/UseCase';
import { useUserContext } from '../hooks/useUserContext';
import { CATEGORIES } from '../models/Category';
import { approximateMinutesUseCase } from '../utils/timeEstimate';
import { logError } from '../utils/log-exception';

interface ChooseUseCaseStep2Props {
  oneOnOne: boolean;
  onSelectUseCaseId: (useCaseId: string, recipient?: string) => void;
  onClose: () => void;
  email?: string;
}

const ChooseUseCaseStep2: React.FC<ChooseUseCaseStep2Props> = ({ onClose, oneOnOne, onSelectUseCaseId, email }) => {
  const [openCategories, setOpenCategories] = useState<{ [key: string]: boolean }>(() =>
    CATEGORIES.reduce((acc, category) => ({ ...acc, [category]: true }), {})
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [useCases, setUseCases] = useState<UseCase[]>([]);
  const { user } = useUserContext()

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const data = await api.getUseCases();
      const filteredData = user.isSuperuser ? data : data.filter(useCase => !useCase.adminOnly)
      setUseCases(filteredData);
    } catch (err) {
      logError(err, "Failed to load use cases");
      onClose();
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const getCategoryUseCases = (category: string): Array<UseCase> => {
    return useCases.filter((useCase) => useCase.category === category.toLocaleLowerCase() && useCase.oneOnOne === oneOnOne);
  };

  const toggleCategory = (category: string) => {
    setOpenCategories((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  if (isLoading) {
    return <FullPageSpinner />;
  }

  const categoriesWithUseCases = CATEGORIES.filter((category) => getCategoryUseCases(category).length > 0);

  return (
    <div className="h-screen-dynamic flex flex-col items-center w-full bg-white overflow-hidden">
      <div className="w-full max-w-3xl bg-skin rounded-2xl shadow-lg overflow-y-auto absolute top-0 p-4">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-xl font-bold">Which situation is most relevant{email && ` for ${email}`}?</h1>
          <button
            onClick={() => onClose()}
            className="mr-2 p-2 pb-1 pt-0 border border-white rounded-lg text-xl bg-white text-gray-600 hover:text-gray-800 focus:outline-none">
            &times;
          </button>
        </div>
        {categoriesWithUseCases.map((categoryName) => (
          <div key={categoryName} className="ml-6 mr-2 mb-4 bg-white rounded-2xl shadow-md">
            <button
              className="w-full text-left p-4 border-b border-gray-300 focus:outline-none"
              onClick={() => toggleCategory(categoryName)}
            >
              <div className="flex justify-between items-center">
                <span className="text-lg">{categoryName === 'HR' ? 'Hard HR Conversations' : categoryName}</span>
                <span className="text-xs">
                  {openCategories[categoryName] ? <FaChevronDown /> : <FaChevronRight />}
                </span>
              </div>
            </button>
            {openCategories[categoryName] && (
              <div className="bg-gray-50 p-4 rounded-2xl">
                {getCategoryUseCases(categoryName).map((useCase) => (
                  <div
                    key={useCase.id}
                    className="flex justify-between items-center p-2 cursor-pointer hover:bg-gray-200 text-black rounded-md"
                    onClick={() => onSelectUseCaseId(useCase.id, useCase.recipient)}
                  >
                    <span className="flex-grow">{useCase.title}</span>
                    <div className="flex items-center">
                      <span className="text-xs text-gray-500 italic flex items-center mr-2">
                        <FaClock className="mr-1" />
                        ~{approximateMinutesUseCase(useCase)}&nbsp;mins
                      </span>
                      <FaChevronRight className="text-gray-400" />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

// 40 seconds per section + 15 seconds / question + 1 minute


export default ChooseUseCaseStep2;